import React from 'react'
import { v4 } from 'uuid'
import { Button, Box, HStack, Tag, TagLeftIcon, TagLabel, Flex, VStack } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GiCutDiamond, GiBeamsAura, GiAmericanShield } from 'react-icons/gi'
import { useTheme } from '@emotion/react'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Features,
  Faqs,
  Video,
  Testimonies,
} from '../components/landingPage'

import {
  H1,
  P,
  Image,
  WhatsappButton,
  Article,
  Hero,
  HeroBody,
  HeroTitle,
  HeroSubtitle,
  H3,
  ColVideo,
  ColInfo,
  RowContent,
  Section,
  FeatureList,
  FeatureItem,
  ColImage,
} from '../ui'
import FloatingBook from '../components/floatingBook'

import Layout from '../components/layout'
import DividerVertical from '../components/dividerVertical'
import { ColTitle, PostTitle, PreTitle, SectionTitle, Title } from '../components/texts'
import ButtonBook from '../components/buttonBook'

import heroPreview from '../images/landing/jets/gallery/6.jpg'
import youtubePreview from '../images/landing/jets/gallery/6.jpg'

import forbesLogo from '../images/index/forbes.jpg'

function IndexPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Layout disableWhatsapp title={t('landing:jets.title')}>
      <Hero
        justifyContent="flex-start"
        height="100vh"
        maxHeight={{ base: '720px', lg: '1440px' }}
        video="https://www.youtube.com/watch?v=A3_lrKPGmH8"
        videoPreview={heroPreview}
      >
        <HeroBody userSelect="all" maxWidth={{ base: '600px' }}>
          <HeroTitle fontSize={{ base: '3rem', md: '4rem' }} textTransform="uppercase">
            {t('landing:jets.hero.title')}
          </HeroTitle>
          <HeroSubtitle>{t('landing:jets.hero.desc')}</HeroSubtitle>
          <ButtonBook pointerEvents="all" as="a" href="#services" size="lg">
            {t('common:viewServices')}
          </ButtonBook>
        </HeroBody>
      </Hero>

      <Article>
        <Section titleAlign="center" justifyContent="center">
          <DividerVertical />
          <Title color="secondary.800">{t('landing:jets.hero.title')}</Title>
          <P textAlign="center">{t('landing:jets.hero.desc')}</P>
          <Flex paddingTop={8} height="100px" justifyContent="center">
            <img style={{ height: '100%', width: 'auto' }} src={forbesLogo} />
          </Flex>
          <DividerVertical />
        </Section>

        <Section id="services" spacing={10}>
          <VStack>
            <PreTitle>EXCLUSIVE PRIVATE SERVICES</PreTitle>
            <SectionTitle>Our Services</SectionTitle>
            <PostTitle>JETS · YACHTS · CONCIERGE</PostTitle>
            <DividerVertical />
          </VStack>

          <VStack spacing={20}>
            <RowContent>
              <ColImage
                borderBottom={`4px solid ${theme.colors.secondary[800]}`}
                image={<Image fixed={data.service1.childImageSharp.fixed} layout="background" />}
              />
              <ColInfo backgroundColor="whiteAlpha.200">
                <ColTitle>Private Jets</ColTitle>
                <P>
                  Personalized attention, you will have VIP access to restaurants, exclusive events,
                  luxury accommodations throughout the Caribbean and sea and land transportation.
                </P>
                <PreTitle textAlign="left">SECURITY & COMFORT</PreTitle>
                <ButtonBook
                  width="fit-content"
                  as="a"
                  href="/landing-jets"
                  size="lg"
                  variant="solid"
                >
                  {t('common:moreInformation')}
                </ButtonBook>
              </ColInfo>
            </RowContent>

            <RowContent reversedInMobile>
              <ColInfo backgroundColor="whiteAlpha.200">
                <ColTitle>Luxury Yachts</ColTitle>
                <P>
                  We have the most luxurious and exclusive fleet of yachts, with the most
                  professional crew to meet the most demanding requirements of our clients.
                </P>
                <PreTitle textAlign="left">HAVE FUN THE LUXURY WAY</PreTitle>
                <ButtonBook width="fit-content" as="a" href="/landing-yachts" size="lg">
                  {t('common:moreInformation')}
                </ButtonBook>
              </ColInfo>
              <ColImage
                borderBottom={`4px solid ${theme.colors.secondary[800]}`}
                image={<Image fixed={data.service2.childImageSharp.fixed} layout="background" />}
              />
            </RowContent>
          </VStack>

          {/* <RowContent>
            <ColImage
              image={<Image fixed={data.why1.childImageSharp.fixed} layout="background" />}
              desc="24/7 Concierge"
            />
            <ColInfo title="24/7 Concierge" backgroundColor={`${theme.colors.secondary[800]}`}>
              <P>
                Personalized attention, you will have VIP access to restaurants, exclusive events,
                luxury accommodations throughout the Caribbean and sea and land transportation.
              </P>
              <Button as="a" href="/landing-yachts" size="lg" colorScheme="blackAlpha">
                View service
              </Button>
            </ColInfo>
          </RowContent> */}
        </Section>

        <Section backgroundColor="whitesmoke">
          <DividerVertical />
          <SectionTitle color="secondary.800">Meet Our Gold Standard</SectionTitle>
          <FeatureList columns={3}>
            <FeatureItem
              paddingY={8}
              align="center"
              textAlign="center"
              // icon={
              //   <Flex
              //     justify="center"
              //     align="center"
              //     borderRadius="100px"
              //     height="70px"
              //     width="70px"
              //     background="linear-gradient(90deg, rgba(179,155,27,1) 0%, rgba(244,225,124,1) 50%, rgba(179,155,27,1) 100%)"
              //   >
              //     <GiAmericanShield size="3rem" />
              //   </Flex>
              // }
              title={t('landing:jets.why.features.one.title')}
              desc={t('landing:jets.why.features.one.desc')}
            />
            <FeatureItem
              paddingY={8}
              align="center"
              textAlign="center"
              // icon={
              //   <Flex
              //     justify="center"
              //     align="center"
              //     borderRadius="100px"
              //     height="70px"
              //     width="70px"
              //     background="linear-gradient(90deg, rgba(179,155,27,1) 0%, rgba(244,225,124,1) 50%, rgba(179,155,27,1) 100%)"
              //   >
              //     <GiBeamsAura size="3rem" />
              //   </Flex>
              // }
              title={t('landing:jets.why.features.two.title')}
              desc={t('landing:jets.why.features.two.desc')}
            />
            <FeatureItem
              paddingY={8}
              align="center"
              textAlign="center"
              // icon={
              //   <Flex
              //     justify="center"
              //     align="center"
              //     borderRadius="100px"
              //     height="70px"
              //     width="70px"
              //     background="linear-gradient(90deg, rgba(179,155,27,1) 0%, rgba(244,225,124,1) 50%, rgba(179,155,27,1) 100%)"
              //   >
              //     <GiCutDiamond size="3rem" />
              //   </Flex>
              // }
              title={t('landing:jets.why.features.three.title')}
              desc={t('landing:jets.why.features.three.desc')}
            />
          </FeatureList>
          <DividerVertical />
          src
        </Section>

        <Video
          light={youtubePreview}
          paddingLeft="0"
          paddingRight="0"
          videoUrl="https://www.youtube.com/watch?v=J9yU6ySp9qw"
        />

        {/* <Testimonies
          title={t('landing:testimonies.title')}
          list={t('landing:testimonies.items', { returnObjects: true })}
          stars={5}
        /> */}

        {/* <FloatingBook
          title={t('landing:jets.floatingBookNow.title')}
          desc={t('landing:jets.floatingBookNow.desc')}
          button={
            <Button colorScheme="primary" flexShrink="0" size="md">
              {t('landing:jets.button')}
            </Button>
          }
        />

        <WhatsappButton
          bottom="75px"
          href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
            'landing:jets.whatsappText'
          )}`}
        /> */}
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero1: file(relativePath: { eq: "index/hero1.jpg" }) {
      ...bannerSectionImage
    }

    hero2: file(relativePath: { eq: "index/hero2.jpg" }) {
      ...bannerSectionImage
    }

    hero3: file(relativePath: { eq: "index/hero3.jpg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "landing/jets/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    service1: file(relativePath: { eq: "index/jets-service.jpg" }) {
      ...fixedImage
    }

    service2: file(relativePath: { eq: "index/yachts-service.jpg" }) {
      ...fixedImage
    }
  }
`

export default IndexPage
