import React from 'react'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'

import { FiCheckCircle } from 'react-icons/fi'

import Layout from '../components/layout'

import {
  Article,
  Section,
  Gallery as UIGallery,
  FeatureItem,
  FeatureList,
  Accordion,
  AccordionItem,
  Hero,
  HeroBody,
} from '../ui'
import ReactPlayer from 'react-player'
import { Flex, HStack } from '@chakra-ui/react'
import { AiFillStar } from 'react-icons/ai'

export function Header({ image, children, ...restProps }) {
  return (
    <Hero image={image} minHeight={{ base: '100vh', md: '500px' }} {...restProps}>
      <HeroBody zIndex={1} pointerEvents="all" maxWidth="100%">
        {children}
      </HeroBody>
    </Hero>
  )
}

Header.propTypes = {
  image: PropTypes.node,
}

export function Features({ title, list, ...restProps }) {
  return (
    <Section title={title} titleAlign="center" {...restProps}>
      <FeatureList>
        {list?.map((item) => (
          <FeatureItem
            key={v4()}
            icon={<FiCheckCircle color="green" size="1.5rem" />}
            image={item.image}
            title={item.title}
            desc={item.desc}
          />
        ))}
      </FeatureList>
    </Section>
  )
}

Features.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
}

export const Video = ({ title, videoUrl, light, ...restProps }) => (
  <Section title={title} titleAlign="center" {...restProps}>
    <Flex
      height={{ base: '200px', sm: '300px', md: '500px', lg: '600px' }}
      width={{ base: '100%', lg: '100%' }}
      marginX="auto"
    >
      <ReactPlayer
        controls={false}
        config={{
          youtube: {
            embedOptions: {
              rel: '0',
            },
          },
        }}
        light={light}
        width="100%"
        height="100%"
        url={videoUrl}
      />
    </Flex>
  </Section>
)

Video.propTypes = {
  light: PropTypes.bool,
  title: PropTypes.string,
  videoUrl: PropTypes.string,
}

Video.defaultProps = {
  light: false,
}

export function Testimonies({ title, list, stars, ...restProps }) {
  return (
    <Section title={title} titleAlign="center" {...restProps}>
      <FeatureList>
        {list?.map((item) => (
          <FeatureItem
            icon={[
              <HStack justify="center">
                {stars &&
                  [...Array(stars).keys()].map(() => <AiFillStar color="gold" size="1.5rem" />)}
              </HStack>,
            ]}
            key={v4()}
            title={item.title}
            desc={item.desc}
            textAlign="center"
          />
        ))}
      </FeatureList>
    </Section>
  )
}

Testimonies.propTypes = {
  stars: PropTypes.number,
  title: PropTypes.string,
  list: PropTypes.array,
}

export function Gallery({ title, images, viewPhotos, ...restProps }) {
  return (
    <Section title={title} titleAlign="center" {...restProps}>
      <UIGallery images={images} buttonText={viewPhotos} />
    </Section>
  )
}

Gallery.propTypes = {
  title: PropTypes.string,
  viewPhotos: PropTypes.string,
  images: PropTypes.array,
}

export function Faqs({ title, list, ...restProps }) {
  return (
    <Section title={title} titleAlign="center" {...restProps}>
      <Accordion marginTop={0} allowMultiple>
        {list?.map((item) => (
          <AccordionItem key={v4()} question={item.question} answer={item.answer} />
        ))}
      </Accordion>
    </Section>
  )
}

Faqs.propTypes = {
  title: PropTypes.string,
  faqs: PropTypes.array,
}

function LandingPage({ children, title, ...restProps }) {
  return (
    <Layout title={title} disableHeader disablePopup disableFloatingBanner {...restProps}>
      <Article backgroundColor="white">{children}</Article>
    </Layout>
  )
}

LandingPage.propTypes = {
  title: PropTypes.string,
  images: PropTypes.any,
}

LandingPage.defaultProps = {
  title: '',
  images: null,
}

export default LandingPage
