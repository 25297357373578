import React from 'react'
import { Flex } from '@chakra-ui/react'

function DividerVertical() {
  return (
    <Flex width="100%" justify="center">
      <Flex height="50px" width="1px" backgroundColor="lightgray" marginY={8} />
    </Flex>
  )
}

export default DividerVertical
